import React, {useEffect, useReducer, useState} from 'react';
import ReactDom from 'react-dom';

import Navbar from "./navbar.js"

import "./index.css";


const Error = () => {
    
    return (
        <>
            <Navbar/>
            <h4 style={{marginTop:"40px"}}>404 Page Not Found</h4>
        </>
    );
}

export default Error;