import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';
import { Link, useParams } from 'react-router-dom'

import Navbar from "./navbar.js"

import ParticleBackground from './particleBackground.js';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import "./project.css";

//import Images from "project_images";

import Footer from './footer.js';

const projectJson = require("./projects.json");



const ProjectBody = () => {

    const { id } = useParams();

    const [project, setProject] = useState({ images: [""] });


    const getProjects = async () => {
        setProject((projectJson.filter((p) => { return p.title == id }))[0]);

    }

    useEffect(() => {
        getProjects();
    }, []);

    /*

    {project.images.map((img)=>{
                                return <img src={img} style={{borderRadius:"30px",width:"300px",height:"300px"}}/>
                            })}

                            <img src={project.images[0]} style={{borderRadius:"30px",width:"300px",height:"300px"}}/>
                            */


    return (
        <div>
            <div className="projectTitle">
                <center>
                    <h4>{project.title}<hr width="100%" /></h4>
                </center>
            </div>
            <div className="projectBody">
                <div style={{ height: "fit-content", padding: "20px", display: "flex", flexWrap: "wrap" }}>
                    <div className="projectImages">
                        <Carousel width="300px" showStatus={false} showThumbs={false} infiniteLoop={true} style={{ margin: "0px", padding: "0px" }}>
                            {project.images.map((img) => {
                                return <img src={img} style={{ borderRadius: "30px", width: "300px" }} />
                            })}
                        </Carousel>
                    </div>
                    <div className="projectBreaker">
                        <div className="verticalBreak">

                        </div>
                    </div>
                    <hr className="hrIfSmallScreen" width="100%" style={{ marginTop: "15px", marginBottom: "15px" }} />
                    <div className="projectDescription">
                        {project.longDescription}
                    </div>
                </div>
            </div>
            <div className="projectTitle">
                <center>
                    <Link to={`/projects`} style={{ textDecoration: "none" }}>
                        <h4 style={{ fontSize: "30px", margin: "-20px" }}>Return to Projects<hr width="100%" /></h4>
                    </Link>

                </center>
            </div>
        </div>
    );
}

const Project = () => {

    return (
        <>
            <Navbar />
            <ProjectBody />
            <Footer />
        </>
    );
}

export default Project;