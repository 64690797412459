import React, {useEffect, useReducer, useState} from 'react';
import ReactDom from 'react-dom';
import Particles from 'react-tsparticles';
import Navbar from "./navbar.js"

import "./index.css";


const ParticleBackground = () => {
    
    const particlesInit = (main) => {
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
      };
    
      const particlesLoaded = (container) => {
      };


      const particleBackgroundProperties = {
        background: {
          color: {
            value: "rgb(60,60,60)",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "bubble",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 6,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#4dc5ac",
          },
          links: {
            color: "#abc9a1",
            distance: 150,
            enable: true,
            opacity: 0.7,
            width: 3,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={particleBackgroundProperties}
        >
        </Particles>
    );
}

export default ParticleBackground;