import React, {useEffect, useReducer, useState, Component } from "react";
import ReactDOM from "react-dom";
import THREE from "./three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader.js';


class App extends Component {
    
    
    
    componentDidMount() {




        // BASIC THREE.JS THINGS: SCENE, CAMERA, RENDERER
        // Three.js Creating a scene tutorial
        // https://threejs.org/docs/index.html#manual/en/introduction/Creating-a-scene
        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(
            75,
            1.0,
            0.1,
            1000
        );
        camera.position.z = 5;

        var renderer = new THREE.WebGLRenderer( { alpha: true } );
        renderer.setSize(300, 300);



        // MOUNT INSIDE OF REACT
        this.mount.appendChild(renderer.domElement); // mount a scene inside of React using a ref



        // CAMERA CONTROLS
        // https://threejs.org/docs/index.html#examples/controls/OrbitControls
        //this.controls = new THREE.OrbitControls(camera);



        // ADD CUBE AND LIGHTS
        // https://threejs.org/docs/index.html#api/en/geometries/BoxGeometry
        // https://threejs.org/docs/scenes/geometry-browser.html#BoxGeometry
        var geometry = new THREE.BoxGeometry(2, 2, 2);
        var material = new THREE.MeshPhongMaterial( {
            color: 0x156289,
            emissive: 0x072534,
            side: THREE.DoubleSide,
            flatShading: true
        } );
        var cube = new THREE.Mesh(geometry, material);
        //scene.add(cube);

        var pivot = new THREE.Group();
        scene.add( pivot );


        const texture = new THREE.TextureLoader().load( "Freethv2.jpeg" );

        const objLoader = new OBJLoader();


        objLoader.load('Freethv2Reduced.obj', (root) => {
            root.scale.set(5,5,5);
            
            pivot.position.set(0,-1,-17)
            root.position.set(0,0,0)

            //root.rotation.y = Math.PI;
            //root.rotation.y += -0.1;
            /*
            var material = new THREE.MeshPhongMaterial( {
                color: 0xffffff,
                emissive: 0x000000,
                side: THREE.DoubleSide,
                flatShading: false
            } );*/

            var material = new THREE.MeshBasicMaterial({
                
            })

            root.children[0].material = material;

            root.children[0].material.map = texture;

            //root.children[0].material.color = new THREE.Color(0xffffff); // 0.5;// /= 1000000.0;
            //root.children[0].material.emissive.set(0xffffff);
            root.children[0].material.shininess /= 20;

            pivot.add(root);
        });

        var face = scene.getObjectByName("");

        var lights = [];
        lights[ 0 ] = new THREE.PointLight( 0xffffff, 0.5, 0 );
        lights[ 1 ] = new THREE.PointLight( 0xffffff, 0.5, 0 );
        lights[ 2 ] = new THREE.PointLight( 0xffffff, 1, 0 );
        lights[ 3 ] = new THREE.PointLight( 0xffffff, 1, 0 );

        const directionalLight1 = new THREE.DirectionalLight( 0xffffff, 3 );
        directionalLight1.position.set(1,0,1)

        const directionalLight2 = new THREE.DirectionalLight( 0xffffff, 1 );
        directionalLight2.position.set(0,0,1)

        scene.add(directionalLight1);
        scene.add(directionalLight2);

        lights[ 0 ].position.set( 0, 200, 0 );
        lights[ 1 ].position.set( 100, 0, 100 );
        lights[ 2 ].position.set( -100, 0, 0 );
        //lights[ 3 ].position.set( 100, 0, 100 );

        //scene.add( lights[ 0 ] );
        //scene.add( lights[ 1 ] );
        //scene.add( lights[ 2 ] );
        //pivot.add( lights[3]);



        // SCALE ON RESIZE

        // Check "How can scene scale be preserved on resize?" section of Three.js FAQ
        // https://threejs.org/docs/index.html#manual/en/introduction/FAQ

        // code below is taken from Three.js fiddle
        // http://jsfiddle.net/Q4Jpu/

        // remember these initial values
        var tanFOV = Math.tan( ( ( Math.PI / 180 ) * camera.fov / 2 ) );
        var windowHeight = window.innerHeight;

        //window.addEventListener( 'resize', onWindowResize, false );

        var x = window.innerWidth;
        var y = window.innerHeight;

        function moveMouse(event){
            //console.log(event.clientX);
            x = event.clientX;
            y = event.clientY;
        }

        window.addEventListener( 'mousemove', moveMouse);

        function onWindowResize( event ) {

            camera.aspect = window.innerWidth / window.innerHeight;

            // adjust the FOV
            camera.fov = ( 360 / Math.PI ) * Math.atan( tanFOV * ( window.innerHeight / windowHeight ) );

            camera.updateProjectionMatrix();
            camera.lookAt( scene.position );

            renderer.setSize( window.innerWidth, window.innerHeight );
            renderer.render( scene, camera );

        }



        // ANIMATE THE SCENE
        var animate = function() {
            requestAnimationFrame(animate);

            pivot.rotation.y = (x - 0.5 * window.innerWidth)/window.innerWidth;
            pivot.rotation.x = (window.scrollY + y - 0.5 * window.innerHeight)/window.innerHeight;

            directionalLight1.position.set(-(x - 0.5 * window.innerWidth)/window.innerWidth,0,1)
            directionalLight1.intensity = 1+1*(x - 0.5 * window.innerWidth)/window.innerWidth;

            var minAxis = Math.min(window.innerWidth*0.6,window.innerHeight*0.6)

            renderer.setSize(minAxis, minAxis);

            renderer.render(scene, camera);

        };

        animate();
    }

    render() {
        return <div ref={ref => (this.mount = ref)} />;
    }
}

export default App