import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';
import { Link } from 'react-router-dom';

import "./navbar.css";
import profilePictureImage from './profile.bmp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';


library.add(faBars);


const Navbar = () => {

    const [open, setOpen] = useState(false);

    const dropDownToggle = () => {
        setOpen(!open)
    }

    const pageN = window.location.pathname.split("/")[1];
    console.log(pageN);

    const [page, setPage] = useState(pageN || "aboutme");


    const [height, setHeight] = useState(65);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    return (
        <>
            <div className="NavbarContainer">
                <div className="Navbar">
                    <>
                        <div className="profilePictureContainer">
                            <img src={profilePictureImage} alt="profile-picture" className="profilePicture" />
                        </div>
                        <div className="nameBar">
                            <div style={{ color: "#3c9cdb" }}>void</div>
                            <div style={{ color: "#ffffff", verticalAlign: "sub" }}>*&nbsp;</div>
                            <div style={{ color: "#89dafa" }}>links</div>
                            <div style={{ color: "#ffffff" }}>&nbsp;=&nbsp;</div>
                            <div style={{ color: "#dcdca5" }}>Jack</div>
                            <div style={{ color: "#ffffff" }}>{'<'}</div>
                            <div style={{ color: "#4dc5ac" }}>Freeth</div>
                            <div style={{ color: "#ffffff" }}>{'>'}&nbsp;</div>
                            <div style={{ color: "#ffffff" }}>{'('}</div>
                            <Link to="/" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("aboutme"); }}>
                                <div style={page === "aboutme" ? { color: "#abc9a1" } : { color: "#89dafa" }}>About_Me</div>
                            </Link>
                            <div style={{ color: "#ffffff" }}>,&nbsp;</div>
                            <Link to="/projects" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("projects") }}>
                                <div style={page === "projects" ? { color: "#abc9a1" } : { color: "#89dafa" }}>Projects</div>
                            </Link>
                            <div style={{ color: "#ffffff" }}>,&nbsp;</div>
                            <Link to="/accolades" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("accolades") }}>
                                <div style={page === "accolades" ? { color: "#abc9a1" } : { color: "#89dafa" }}>Accolades</div>
                            </Link>

                            <div style={{ color: "#ffffff" }}>);</div>
                        </div>
                        <div className="smallWidthName">
                            <div style={{ color: "#3c9cdb" }}>
                                Jack&nbsp;
                            </div>
                            <div style={{ color: "#4dc5ac" }}>
                                Freeth
                            </div>
                        </div>
                        <div className="dropDownButtonContainer">
                            <button onClick={() => { dropDownToggle() }}>
                                <FontAwesomeIcon icon="fa-solid fa-bars" className="dropDownButton" style={{ height: "30px", cursor: "pointer" }} />
                            </button>
                        </div>
                    </>



                </div>

                <hr />
                {open && screenWidth < 768 &&
                    <div className="dropDown">

                        <center>
                            <Link to="/" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("aboutme"); }}>
                                <div style={page === "aboutme" ? { color: "#abc9a1" } : { color: "#89dafa" }}>About_Me</div>
                            </Link>

                            <Link to="/projects" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("projects") }}>
                                <div style={page === "projects" ? { color: "#abc9a1" } : { color: "#89dafa" }}>Projects</div>
                            </Link>
                            <Link to="/accolades" style={{ textDecoration: 'none' }} onClick={(e) => { setPage("accolades") }}>
                                <div style={page === "accolades" ? { color: "#abc9a1" } : { color: "#89dafa" }}>Accolades</div>
                            </Link>

                        </center>

                    </div>
                }


                <hr />

            </div>
            <div style={{ height: height + open * 95 }}>

            </div>
        </>


    );
}

/*
<div style={{color:"#ffffff"}}>,&nbsp;</div>
                    <a href="./index1.html" style={{ textDecoration: 'none' }}>
                    <div style={{color:"#89dafa"}}>The_Better_Portfolio</div>
                    </a>




                    <a href="./index1.html" style={{ textDecoration: 'none' }}>
                            <div style={{color:"#89dafa"}}>The_Better_Portfolio</div>
                        </a>
*/

export default Navbar;
