import React, { useEffect, useReducer, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Link, withRouter } from 'react-router-dom'

import AboutMe from './AboutMe.js'
import Projects from './Projects.js'
import Project from './Project.js'
import Accolades from './Accolades.js'
import Error from './error.js'

import "./index.css";


import ParticleBackground from './particleBackground.js';

// const Route = ({ path, children }) => {
//     return window.location.pathname === path ? children : null
//   }

const StaticPortfolio = () => {
    //${process.env.PUBLIC_URL}
    return (<>

        <Router basename={`/${process.env.PUBLIC_URL}`}>
            <Routes>

                <Route exact path="/" element={
                    <>
                        <AboutMe />
                    </>
                } />
                <Route exact path="/about-me" element={
                    <>
                        <AboutMe />
                    </>
                } />
                <Route exact path="/projects" element={
                    <>
                        <Projects />
                    </>
                } />
                <Route path="/projects/:id" element={
                    <Project />
                } />
                <Route exact path="/accolades" element={
                    <>
                        <Accolades />
                    </>
                } />
                <Route path="*" element={
                    <>
                        <Error />
                    </>
                } />
            </Routes>
            <ParticleBackground />
        </Router>
    </>
    )

}



//ReactDom.render(<StaticPortfolio/>,document.getElementById('root'));

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

// Initial render
root.render(<StaticPortfolio />);

// During an update, there is no need to pass the container again
root.render(<StaticPortfolio />);

//<Route path="/Portfolio/"><AboutMe/></Route>
//<Route path="/Portfolio/projects"><Projects/></Route>