import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';
import { Link } from 'react-router-dom'

import Navbar from "./navbar.js"
import Footer from "./footer.js"

import Particles from 'react-tsparticles';

import ParticleBackground from './particleBackground.js';

import "./index.css";

//const script = require("https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.min.js");


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';





import "./footer.css";
import profilePictureImage from './profile.png'


library.add(fab);

library.add(faStar);



const projectJson = require("./projects.json");


const ProjectBody = () => {

    const [projects, setProjects] = useState([]);

    const getProjects = async () => {
        setProjects(projectJson);
    }

    useEffect(() => {
        getProjects();
    }, []);

    /*<FontAwesomeIcon icon="fa-star" style={{marginBottom:"2px",height:"25px",color:"gold"}}/>*/

    return (
        <>


            <div className="projectPageTitle">
                <center>
                    <h4>Projects<hr width="100%" /></h4>

                </center>
            </div>


            <div className="pageBody">
                <div className="projects">

                    {projects.map((project) => {
                        return (
                            <div className="projectContainer">
                                <Link to={`/projects/${project.title}`} className="projectImageContainer">
                                    <img src={project.images[0]} className="projectImage" />
                                </Link>

                                <div className="projectTextContainer">
                                    <Link to={`/projects/${project.title}`} style={{ textDecoration: "none" }}>
                                        <h2 style={{ margin: "5px 20px" }}>
                                            {project.title}&nbsp;
                                        </h2>

                                    </Link>

                                    <hr width="100%" />
                                    <h4 style={{ margin: "20px" }}>{project.shortDescription}</h4>
                                    <hr width="100%" />
                                    <h4 style={{ margin: "20px" }}>{project.technologies.map((technology) => {
                                        return technology + " ";
                                    })}</h4>

                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>

            {projects.length == 0 ? <div></div> : <Footer />}
        </>
    );
}

const Projects = () => {

    return (
        <>
            <Navbar />
            <ProjectBody></ProjectBody>

        </>
    );
}

//<script src="https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.min.js"></script>

export default Projects;