import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';





import "./footer.css";
import profilePictureImage from './profile.png'


library.add(fab);

library.add(faEnvelope);



const Footer = () => {


    return (
        <>
            <div className="FooterContainer">
                <div className="footerTop">
                    <FontAwesomeIcon icon="fa-brands fa-github" className="contactButton" style={{ marginRight: "20px", height: "50px", cursor: "pointer" }} onClick={() => { window.location.href = "https://github.com/RHOMBI" }} />
                    <FontAwesomeIcon icon="fa-solid fa-envelope" className="contactButton" style={{ marginRight: "20px", height: "50px", cursor: "pointer" }} onClick={() => { window.location.href = "mailto: jackwfreeth@gmail.com" }} />
                    <FontAwesomeIcon icon="fa-brands fa-linkedin" className="contactButton" style={{ marginRight: "20px", height: "50px", cursor: "pointer" }} onClick={() => { window.location.href = "https://www.linkedin.com/in/jack-freeth-327277218/" }} />
                    <FontAwesomeIcon icon="fa-brands fa-discord" className="contactButton" style={{ height: "50px", cursor: "pointer" }} onClick={() => { window.location.href = "https://discordapp.com/users/291486779222261760" }} />
                </div>
                <div className="footerBottom">
                    <h4>©2024 Jack Freeth</h4>
                </div>
            </div>
        </>


    );
}

export default Footer;

